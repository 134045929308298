import React, { useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import * as THREE from 'three';
const TWEEN = require('@tweenjs/tween.js');

const Wrapper = styled.div`
  position: relative;
  z-index: ${({ show }) => (show ? 1 : -1)};

  canvas {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: ${({ show }) => (show ? 1 : 0.2)};
    z-index: ${({ show }) => (show ? 1 : -1)};
    pointer-events: none;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
  }
`;

const CHeading = styled.h2`
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 40px;
  display: block;
  position: fixed;
  top: 80px;
  left: 20px;
  z-index: 9;

  &.m-hide {
    display: none;
  }

  @media only screen and (max-width: 810px) {
    font-size: 26px;
    top: 65px;
  }
`;

const CMore = styled.div`
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 40px;
  display: block;
  position: fixed;
  left: 20px;
  bottom: 40px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media only screen and (max-width: 810px) {
    font-size: 26px;
    bottom: 20px;
  }

  svg {
    margin-top: 16px;
    margin-left: 5px;

    @media only screen and (max-width: 810px) {
      height: 20px;
      width: 44px;
    }

    path {
      stroke: ${({ theme }) => theme.colorMain};
    }
  }

  &.m-hide {
    display: none;
  }
`;

const CanvasWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ show }) => (show ? -1 : 1)};
  min-height: 100vh;
  min-height: calc((var(--vh, 1vh) * 100));
`;
const Canvas = ({ show, inverted, items, setContext, theme, isEn }) => {

useEffect(() => {
  if (typeof window !== 'undefined') {
    if (isEn) {
      document.body.classList.add('lang-en');
    } else {
      document.body.classList.remove('lang-en');
    }
  }
}, [isEn])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      var canv = document.createElement('canvas');
      canv.id = 'c';
      if (document.getElementById('c-wrapper')) {
        document.getElementById('c-wrapper').appendChild(canv);
      }
      // Custom class
      class projectPlane {
        texture;
        isVideo;
        poster;
        material = new THREE.MeshBasicMaterial({
          transparent: true,
          opacity: 0.0,
        });
        geometry = new THREE.PlaneGeometry();
        mesh = new THREE.Mesh(this.geometry, this.material);

        blendMat = new THREE.MeshBasicMaterial({
          color:
            this.themeCol === 'black'
              ? 0x000000
              : this.themeCol === 'white'
              ? 0xe4e4e4
              : 0x00B653,
          // color: this.isInverted ? 0xeafc68 : 0x00B653,
          transparent: true,
          opacity: 0.0,
        });
        blendGeo = new THREE.PlaneGeometry();
        blendMesh = new THREE.Mesh(this.blendGeo, this.blendMat);

        ratio;
        pos = {
          x: 0,
          y: 0,
          z: 0,
        };
        uuid;
        rot = {
          x: 0,
          y: 0,
          z: 0,
        };
        selected = false;
        visible = false;
        loaded = false;
        videoLoaded = false;
        index;
        target = new THREE.Vector3(0, 0, 100);
        vid;
        isInverted = false;
        themeCol = 'black';
        currentTheme = 'black';

        static playingNo = 0;
        static get maxVideosPlaying() {
          return 6;
        }

        constructor(x, y, z, s, video, img, poster, index, handle, name) {
          this.pos.x = x;
          this.pos.y = y;
          this.pos.z = z;
          this.size = s;
          this.image = img;
          this.isVideo = video;
          this.index = index;
          this.handle = handle;
          this.name = name;
          this.poster = poster;
        }

        load() {
          if (this.isVideo) this.videoInit();
          else this.pictureInit();
          this.loaded = true;
          //console.log("loading:", this.image);
        }

        videoInit() {
          this.poster = new THREE.TextureLoader().load(this.poster, () => {
            //console.log("poster loaded!")
          });
          this.material.map = this.poster;
          this.material.opacity = 1;
          this.material.needsUpdate = true;
          this.visible = true;
          this.mesh.layers.enable(1);
          this.mesh.visible = true;
          scene.add(this.mesh);

          this.uuid = this.mesh.uuid;
          this.mesh.x = this.pos.x;
          this.mesh.y = this.pos.y;
          this.mesh.z = this.pos.z;

          this.vid = document.createElement('video');
          this.vid.preload = 'auto';
          this.vid.setAttribute('playsinline', '');
          this.vid.setAttribute('crossorigin', 'anonymous');
          this.texture = new THREE.VideoTexture(this.vid);
          let source = document.createElement('source');
          source.type = 'video/mp4';

          fetch(this.image, {
            method: 'HEAD',
          }).then(response => {
            // console.log(response)
            source.src = response.url;
            this.vid.appendChild(source);
          });

          this.playVideo();
          this.vid.addEventListener(
            'loadedmetadata',
            this.resize.bind(this),
            false
          );

          this.vid.addEventListener('progress', () => {
            try {
              this.videoLoaded = true;
              this.playVideo();
            } catch (err) {
              console.log('load err ' + err);
            }
          });

          this.vid.loop = true;
          this.vid.muted = true;
          //this.vid.pause();
          this.vid.volume = 0.5;
          this.vid.loop = true;
          this.vid.muted = true;
          //this.vid.pause();
          this.vid.volume = 0.5;
        }

        pictureInit() {
          this.texture = new THREE.TextureLoader().load(
            this.image,
            this.resize.bind(this)
          );
          //texture.minFilter = THREE.LinearFilter;
          this.material.map = this.texture;
          this.material.opacity = 0;
          //console.log(this.mesh.renderOrder);
          scene.add(this.mesh);
          //this.mesh.material.color.setHex( 0x00B653 );
          this.uuid = this.mesh.uuid;
          this.mesh.position.x = this.pos.x;
          this.mesh.position.y = this.pos.y;
          this.mesh.position.z = this.pos.z;
          this.mesh.layers.enable(1);
          this.show();
        }

        blendPlane() {
          this.blendMesh.position.z = 0.02;
          this.blendMesh.scale.x = 1.01;
          this.blendMesh.scale.y = 1.01;
          this.mesh.add(this.blendMesh);
        }

        hide() {
          this.visible = false;
          this.mesh.layers.disable(1);
          let tweenoff = new TWEEN.Tween(this.material)
            .to(
              {
                opacity: 0,
              },
              250
            )
            .onComplete(
              function () {
                this.mesh.visible = false;
                if (this.isVideo && this.videoLoaded) this.stopVideo();
              }.bind(this)
            )
            .start();
        }

        show() {
          if (this.isVideo && this.videoLoaded) this.playVideo();
          this.visible = true;
          this.mesh.layers.enable(1);
          this.mesh.visible = true;
          let tweenoff = new TWEEN.Tween(this.material)
            .to(
              {
                opacity: 1,
              },
              250
            )
            .start();
        }

        stopVideo() {
          if (this.videoLoaded) {
            this.vid.pause();
            if (projectPlane.playingNo > 0) projectPlane.playingNo--;
            this.material.map = this.poster;
            // console.log('%cvideo ' + this.name + ' stopped', 'color:red');
            // console.log("total playing: " + projectPlane.playingNo);
          }
        }

        playVideo() {
          if (Math.random() > 0.5) {
            if (
              this.videoLoaded &&
              projectPlane.playingNo < projectPlane.maxVideosPlaying
            ) {
              this.material.map = this.texture;
              this.vid.play();
              projectPlane.playingNo++;
              // console.log('%cvideo ' + this.name + ' started', 'color:green');
              // console.log("total playing: " + projectPlane.playingNo);
            }
          }
        }

        resize() {
          //this.ratio = tex.image.width / tex.image.height;
          if (this.isVideo) {
            this.ratio =
              this.texture.image.videoWidth / this.texture.image.videoHeight;
            //console.log(this.ratio);
          } else {
            this.ratio = this.texture.image.width / this.texture.image.height;
          }
          this.mesh.scale.x = this.size * this.ratio;
          this.mesh.scale.y = this.size;
          //this.mesh.lookAt(this.target);
          this.rot.x = this.mesh.rotation.x;
          this.rot.y = this.mesh.rotation.y;
          this.rot.z = this.mesh.rotation.z;
          this.blendPlane();
        }

        update() {
          this.mesh.position.x = this.pos.x;
          this.mesh.position.y = this.pos.y;
          this.mesh.position.z = this.pos.z;

          this.mesh.rotation.x = this.rot.x;
          this.mesh.rotation.y = this.rot.y;
          this.mesh.rotation.z = this.rot.z;

          this.mesh.scale.x = this.size * this.ratio;
          this.mesh.scale.y = this.size;
          //this.mesh.lookAt(this.target);
        }

        destroy() {
          this.geometry.dispose();
          this.material.dispose();
          scene.remove(this.mesh);
          renderer.renderLists.dispose();
        }
      }

      // === THREE.JS CODE START ===

      let scene = new THREE.Scene();
      let isInverted = document.body.classList.contains('inverted');
      let currentTheme = document.body.classList.contains('col-black')
        ? 'black'
        : document.body.classList.contains('col-white')
        ? 'white'
        : 'green';
      const color = 0x00B653;
      const colorInverted = 0xeafc68;

      const getColor = () => {
        if (document.body.classList.contains('col-black')) {
          return 0x000000;
        } else if (document.body.classList.contains('col-white')) {
          return 0xe4e4e4;
        } else {
          return 0x00B653;
        }
      };

      // const getInvertedColor = () => {
      //   if (document.body.classList.contains('col-black')) {
      //     return 0xe4e4e4;
      //   } else if (document.body.classList.contains('col-white')) {
      //     return 0x000000;
      //   } else {
      //     return 0xe4e4e4;
      //   }
      // };

      scene.fog = new THREE.Fog(getColor(), 75, 200);
      // scene.fog = new THREE.Fog(isInverted ? colorInverted : color, 75, 200);

      const canvas = document.querySelector('#c');

      const renderer = new THREE.WebGLRenderer({
        canvas,
      });

      const fov = 50;
      const aspect = 2; // the canvas default
      const near = 0.1;
      const far = 200;
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.z = 20;

      renderer.setClearColor(getColor(), 1);

      let selectedTitle = '';
      let selectedHandler = '';

      ///////////////////////////// TITLE DIV

      const titleDiv = document.getElementById('c-heading');

      ///////////////////////////// MORE DIV

      const moreDiv = document.getElementById('c-more-wrapper');
      const moreSpan = document.getElementById('c-more');

      moreDiv.addEventListener(
        'click',
        function () {
          if (selectedHandler) {
            navigate(`/works/${selectedHandler}`);
          } else {
            navigate('/works');
          }
        },
        false
      );

      ///////////////////////////// BROWSER CHECK

      let isMobile = false;

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        isMobile = true;
      } else {
        isMobile = false;
      }

      /////////////////////// RAYCASTER

      const raycaster = new THREE.Raycaster();
      raycaster.layers.set(1);
      let selectedPlane = null;

      //////////////////////// STATS
      // let stats;
      // stats = new Stats();
      // document.body.appendChild(stats.dom);

      ////////////////////// GUI
      let params = {
        camRot: 0.15,
        planeSize: 12,
        planeSpread: 32,
        visFar: 200,
        visNear: 20,
        depthSpread: 10,
        fogStart: 75,
        fogEnd: 200,
      };

      // let gui = new dat.gui.GUI({});

      // gui.add(params, 'camRot').min(0.01).max(2);
      // gui.add(params, 'visFar').min(50).max(1000);
      // gui.add(params, 'visNear').min(0).max(50);
      // gui.add(params, 'fogStart').min(0).max(500).onChange(changeParams);
      // gui.add(params, 'fogEnd').min(0).max(500).onChange(changeParams);
      // gui.add(params, 'planeSize').min(5).max(20).onChange(changeParams);
      // gui.add(params, 'planeSpread').min(10).max(100);
      // gui.add(params, 'depthSpread').min(0).max(20);
      // gui.closed = true;

      function changeParams() {
        for (let i = 0; i < projectsArr.length; i++) {
          projectsArr[i].size = params.planeSize;
          projectsArr[i].update();
        }
        scene.fog.near = params.fogStart;
        scene.fog.far = params.fogEnd;
      }

      // let timeout;
      // let timeout2;

      const handleHomeScroll = e => {
        if (
          document.querySelector('#___gatsby > div').offsetHeight -
            window.innerHeight ===
          parseInt(document.getElementById('___gatsby').scrollTop)
        ) {
          setContext({
            showcase: true,
          });
        }
      };

      const handleHomeScrollBody = e => {
        if (
          document.body.offsetHeight - window.innerHeight ===
          parseInt(window.scrollY)
        ) {
          setContext({
            showcase: true,
          });
        }
      };

      /////////////////////// EVENTS

      document.addEventListener('wheel', onDocumentWheel, false);

      document.addEventListener(
        'touchstart',
        function (event) {
          event.preventDefault();
        },
        false
      );

      document.addEventListener('touchmove', onDocumentTouchmove, false);
      document.addEventListener('mousemove', onDocumentMouseMove, false);
      document.addEventListener('click', onDocumentClick, false);

      // document.addEventListener('scroll', handleHomeScrollBody);
      // document
      //   .getElementById('___gatsby')
      //   .addEventListener('scroll', handleHomeScroll);

      let plusZ = 0;

      function onDocumentWheel(event) {
        if (selectedPlane == null) plusZ += event.deltaY / 200;
      }

      let moveY = 0; //current y pos
      let moveY2 = moveY; //previous y pos
      let error = 5; //touch sensitivity, I found between 4 and 7 to be good values.

      function onDocumentTouchmove(event) {
        //get current y pos
        moveY = event.changedTouches[0].clientY;
        //ingnore user jitter
        if (Math.abs(moveY - moveY2) > error) {
          //find direction of y
          if (moveY > moveY2) {
            if (selectedPlane == null) plusZ += moveY / 1000;
          } else {
            if (selectedPlane == null) plusZ -= moveY / 1000;
          }
          //store current y pos
          moveY2 = moveY;
        }
        return false;
      }

      let mouse = new THREE.Vector2();
      let camTargetRotX = 0;
      let camTargetRotY = 0;

      let unmutedVideo = null;

      function onDocumentClick(event) {
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);

        if (
          intersects.length > 0 &&
          selectedPlane == null &&
          intersects[0].object.visible
          // &&
          // document.body.classList.contains('showoff')
        ) {
          let parent;

          setContext({
            showcase: true,
          });

          /////// SELECT
          for (let i = 0; i < projectsArr.length; i++) {
            if (intersects[0].object.uuid == projectsArr[i].uuid) {
              parent = projectsArr[i];
              selectedPlane = projectsArr[i].uuid;
              projectsArr[i].selected = true;

              selectedTitle = projectsArr[i].name;
              selectedHandler = projectsArr[i].handle;
            } else {
              projectsArr[i].blendMesh.material.opacity = 0.9;
            }

            // if (document.body.classList.contains('inverted')) {
            //   projectsArr[i].blendMesh.material.color.setHex(0xeafc68);
            // } else {
            //   projectsArr[i].blendMesh.material.color.setHex(0x00B653);
            // }

            if (document.body.classList.contains('col-black')) {
              projectsArr[i].blendMesh.material.color.setHex(0x000000);
            } else if (document.body.classList.contains('col-white')) {
              projectsArr[i].blendMesh.material.color.setHex(0xe4e4e4);
            } else {
              projectsArr[i].blendMesh.material.color.setHex(0x00B653);
            }
          }

          if (parent.isVideo) {
            for (let i = 0; i < projectsArr.length; i++) {
              if (
                projectsArr[i].isVideo &&
                projectsArr[i].visible &&
                projectsArr[i].uuid != parent.uuid
              ) {
                projectsArr[i].vid.pause();
              }
            }

            if (parent.videoLoaded) {
              unmutedVideo = parent.texture.image;
              if (parent.videoLoaded) parent.texture.image.muted = false;
            }
          }

          let localpos = new THREE.Vector3();
          localpos = camera.worldToLocal(intersects[0].object.position);
          intersects[0].object.parent = camera;

          ///////// TWEEN

          let cameraZ = camera.position.z;
          let planeZ = 4;
          let distance = cameraZ - planeZ;
          let aspect =
            intersects[0].object.scale.x / intersects[0].object.scale.y;
          let screenAspect = window.innerWidth / window.innerHeight;
          let vFov = (camera.fov * Math.PI) / 180;
          let hFov = 2 * Math.atan(Math.tan(vFov / 2) * aspect);
          let planeHeightAtDistance = 2 * Math.tan(vFov / 2) * distance;
          let planeWidthAtDistance = planeHeightAtDistance * aspect;
          if (window.innerWidth < window.innerHeight) {
            planeWidthAtDistance = planeHeightAtDistance * screenAspect;
            planeHeightAtDistance = planeWidthAtDistance / aspect;
          }

          const coords = {
            x: localpos.x,
            y: localpos.y,
            z: localpos.z,
            rotx: intersects[0].object.rotation.x,
            roty: intersects[0].object.rotation.y,
            rotz: intersects[0].object.rotation.z,
            scalex: intersects[0].object.scale.x,
            scaley: intersects[0].object.scale.y,
          };

          var tween = new TWEEN.Tween(coords)
            .to(
              {
                x: 0,
                y: 0,
                z: -20,
                rotx: 0,
                roty: 0,
                rotz: 0,
                scalex: planeWidthAtDistance,
                scaley: planeHeightAtDistance,
              },
              500
            )
            .easing(TWEEN.Easing.Back.Out)
            .onUpdate(() => {
              intersects[0].object.position.x = coords.x;
              intersects[0].object.position.y = coords.y;
              intersects[0].object.position.z = coords.z;
              intersects[0].object.rotation.x = coords.rotx;
              intersects[0].object.rotation.y = coords.roty;
              intersects[0].object.rotation.z = coords.rotz;
              intersects[0].object.scale.x = coords.scalex;
              intersects[0].object.scale.y = coords.scaley;
            })
            .onComplete(function () {
              titleDiv.style.display = 'flex';
              titleDiv.innerHTML = selectedTitle;
              if (!!selectedHandler) {
                moreDiv.style.display = 'flex';
                moreSpan.innerHTML = document.body.classList.contains('lang-en') ? 'more' : 'więcej';
              }
            })
            .start();

          //blendMesh.renderOrder = 998;
          //scene.fog.near = 20;
          //scene.fog.far = 50;
        } else {
          ///////////// CLOSE

          if (unmutedVideo != null) {
            unmutedVideo.muted = true;
            unmutedVideo = null;
            for (let i = 0; i < projectsArr.length; i++) {
              if (projectsArr[i].isVideo && projectsArr[i].visible) {
                projectsArr[i].vid.play();
              }
            }
          }

          selectedPlane = null;

          titleDiv.style.display = 'none';
          titleDiv.innerHTML = '';
          moreDiv.style.display = 'none';
          moreSpan.innerHTML = '';

          //blendMesh.position.z = 100;
          //blendMesh.renderOrder = 0;
          for (let i = 0; i < projectsArr.length; i++) {
            projectsArr[i].blendMesh.material.opacity = 0.0;
            projectsArr[i].mesh.parent = scene;
            projectsArr[i].selected = false;
            projectsArr[i].update();
          }
        }
      }

      function onDocumentMouseMove(event) {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        if (!isMobile) {
          camTargetRotX = mouse.y * params.camRot;
          camTargetRotY = -mouse.x * params.camRot;
        }

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);
        if (intersects.length > 0) {
          if (intersects[0].object.visible)
            document.body.style.cursor = document.body.classList.contains(
              'showoff'
            )
              ? 'pointer'
              : 'default';
        } else {
          document.body.style.cursor = 'default';
        }
      }

      /////////////////////// PLANES

      let projectsArr = [];

      function getRnd(min, max) {
        return Math.random() * (max - min) + min;
      }

      // function newPlane(n, vid, url) {
      //   let p = new projectPlane(
      //     getRnd(
      //       -params.planeSpread * camera.aspect,
      //       params.planeSpread * camera.aspect
      //     ),
      //     getRnd(-params.planeSpread, params.planeSpread),
      //     -20 - n * params.depthSpread,
      //     params.planeSize,
      //     vid,
      //     url,
      //     n
      //   );
      //   projectsArr.push(p);
      // }

      function newPlane(n, vid, url, han, tit, poster) {
        let p = new projectPlane(
          getRnd(
            -params.planeSpread * camera.aspect,
            params.planeSpread * camera.aspect
          ),
          getRnd(-params.planeSpread, params.planeSpread),
          -20 - n * params.depthSpread,
          params.planeSize,
          vid,
          url,
          poster ? poster : false,
          n,
          han,
          tit
        );
        projectsArr.push(p);
      }

      function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        const pixelRatio = window.devicePixelRatio;
        const width = Math.round(canvas.clientWidth * pixelRatio);
        const height = Math.round(document.body.offsetHeight * pixelRatio);
        // const height = Math.round(window.innerHeight * pixelRatio);
        // const height = Math.round(canvas.clientHeight * pixelRatio);
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
          renderer.setSize(width, height, false);
        }
        return needResize;
      }

      /////////////////////// ANIMATE

      let animate = function (time) {
        if (resizeRendererToDisplaySize(renderer)) {
          const canvas = renderer.domElement;
          camera.aspect = canvas.clientWidth / canvas.clientHeight;
          camera.updateProjectionMatrix();
        }

        plusZ += (0 - plusZ) * 0.05;

        // if (plusZ > 1 || plusZ < -1) {
        //   if (titleDiv.style.display !== 'none') {
        //     if (unmutedVideo != null) {
        //       unmutedVideo.muted = true;
        //       unmutedVideo = null;
        //       for (let i = 0; i < projectsArr.length; i++) {
        //         if (projectsArr[i].isVideo && projectsArr[i].visible) {
        //           projectsArr[i].vid.play();
        //         }
        //       }
        //     }

        //     selectedPlane = null;

        //     titleDiv.style.display = 'none';
        //     titleDiv.innerHTML = '';
        //     moreDiv.style.display = 'none';
        //     moreSpan.innerHTML = '';

        //     //blendMesh.position.z = 100;
        //     //blendMesh.renderOrder = 0;
        //     for (let i = 0; i < projectsArr.length; i++) {
        //       projectsArr[i].blendMesh.material.opacity = 0.0;
        //       projectsArr[i].mesh.parent = scene;
        //       projectsArr[i].selected = false;
        //       projectsArr[i].update();
        //     }
        //   }
        // }

        for (let i = 0; i < projectsArr.length; i++) {
          if (selectedPlane == null) projectsArr[i].pos.z += plusZ;

          if (
            projectsArr[i].pos.z >= -params.visFar &&
            projectsArr[i].pos.z <= -params.visNear
          ) {
            if (!projectsArr[i].loaded) projectsArr[i].load();
            if (!projectsArr[i].visible) {
              projectsArr[i].show();
            }
          } else {
            if (projectsArr[i].loaded && projectsArr[i].visible) {
              projectsArr[i].hide();
            }
          }

          if (projectsArr[i].pos.z > camera.position.z - params.visNear) {
            projectsArr[i].pos.x = getRnd(
              -params.planeSpread * camera.aspect,
              params.planeSpread * camera.aspect
            );
            projectsArr[i].pos.y = getRnd(
              -params.planeSpread,
              params.planeSpread
            );
            projectsArr[i].pos.z =
              projectsArr[i].pos.z - projectsArr.length * params.depthSpread;
            projectsArr[i].update();

            projectsArr[i].rot.x = projectsArr[i].mesh.rotation.x;
            projectsArr[i].rot.y = projectsArr[i].mesh.rotation.y;
            projectsArr[i].rot.z = projectsArr[i].mesh.rotation.z;
          }

          if (projectsArr[i].pos.z < -projectsArr.length * params.depthSpread) {
            projectsArr[i].pos.x = getRnd(
              -params.planeSpread * camera.aspect,
              params.planeSpread * camera.aspect
            );
            projectsArr[i].pos.y = getRnd(
              -params.planeSpread,
              params.planeSpread
            );
            projectsArr[i].pos.z =
              projectsArr[i].pos.z + projectsArr.length * params.depthSpread;
            projectsArr[i].update();

            projectsArr[i].rot.x = projectsArr[i].mesh.rotation.x;
            projectsArr[i].rot.y = projectsArr[i].mesh.rotation.y;
            projectsArr[i].rot.z = projectsArr[i].mesh.rotation.z;
          }

          if (projectsArr[i].uuid != selectedPlane) {
            projectsArr[i].update();
          }
        }

        camera.rotation.x += (camTargetRotX - camera.rotation.x) * 0.03;
        camera.rotation.y += (camTargetRotY - camera.rotation.y) * 0.03;

        let currentBodyTheme = document.body.classList.contains('col-black')
          ? 'black'
          : document.body.classList.contains('col-white')
          ? 'white'
          : 'green';

        if (currentTheme !== currentBodyTheme) {
          scene.fog = new THREE.Fog(
            currentBodyTheme === 'black'
              ? 0x000000
              : currentBodyTheme === 'white'
              ? 0xe4e4e4
              : 0x00B653,
            75,
            200
          );
        }

        renderer.setClearColor(
          currentBodyTheme === 'black'
            ? 0x000000
            : currentBodyTheme === 'white'
            ? 0xe4e4e4
            : 0x00B653,
          1
        );

        currentTheme = currentBodyTheme;

        // if (isInverted !== document.body.classList.contains('inverted')) {
        //   scene.fog = new THREE.Fog(
        //     document.body.classList.contains('inverted')
        //       ? colorInverted
        //       : color,
        //     75,
        //     200
        //   );

        //   renderer.setClearColor(
        //     document.body.classList.contains('inverted')
        //       ? colorInverted
        //       : color,
        //     1
        //   );

        //   isInverted = document.body.classList.contains('inverted');

        // }

        TWEEN.update(time);

        //stats.begin();

        renderer.render(scene, camera);
        if (!document.body.classList.contains('other-page')) {
          requestAnimationFrame(animate);
        } else {
          projectsArr = [];
          document.removeEventListener('wheel', onDocumentWheel, false);

          document.removeEventListener(
            'touchstart',
            function (event) {
              event.preventDefault();
            },
            false
          );

          document.removeEventListener('touchmove', onDocumentTouchmove, false);
          document.removeEventListener('mousemove', onDocumentMouseMove, false);
          document.removeEventListener('click', onDocumentClick, false);

          // document.removeEventListener('scroll', handleHomeScrollBody);
          // document
          //   .getElementById('___gatsby')
          //   .removeEventListener('scroll', handleHomeScroll);
        }
        //stats.end();
      };

      // === THREE.JS EXAMPLE CODE END ===

      items.forEach((item, i) => {
        if (item.localizations) {
          if (item.videoUrl) {
            newPlane(
              i,
              true,
              item.videoUrl,
              item.project && item.project.slug,
              item.name,
              item.localizations[0].file[0] &&
                item.localizations[0].file[0].url &&
                item.localizations[0].file[0].url
            );
          } else {
            if (item.file[0]) {
              if (item.file[0].mimeType.indexOf('image') > -1) {
                newPlane(
                  i,
                  false,
                  item.localizations[0].file[0].url,
                  item.project && item.project.slug,
                  item.name
                );
              }
            }
          }
        } else {
          if (item.videoUrl) {
            newPlane(
              i,
              true,
              item.videoUrl,
              item.project && item.project.slug,
              item.name,
              item.file[0] && item.file[0].url && item.file[0].url
            );
          } else {
            if (item.file[0]) {
              if (item.file[0].mimeType.indexOf('image') > -1) {
                newPlane(
                  i,
                  false,
                  item.file[0].url,
                  item.project && item.project.slug,
                  item.name
                );
              }
            }
          }
        }
      });

      setTimeout(() => {
        animate();
      }, 50);
    }

    return () => {
      var oldcanv = document.getElementById('c');
      if (document.getElementById('c-wrapper')) {
        document.getElementById('c-wrapper').removeChild(oldcanv);
      }
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.body.classList.add('showoff');
      document.documentElement.classList.add('showoff');
    } else {
      document.body.classList.remove('showoff');
      document.documentElement.classList.remove('showoff');
    }
  }, [show]);

  // useEffect(() => {
  //   if (inverted) {
  //     document.body.classList.add('inverted');
  //   } else {
  //     document.body.classList.remove('inverted');
  //   }
  // }, [inverted]);

  useEffect(() => {
    if (theme === 'black') {
      document.body.classList.remove('col-green');
      document.body.classList.remove('col-white');
      document.body.classList.add('col-black');
    } else if (theme === 'white') {
      document.body.classList.remove('col-black');
      document.body.classList.remove('col-green');
      document.body.classList.add('col-white');
    } else {
      document.body.classList.remove('col-black');
      document.body.classList.remove('col-white');
      document.body.classList.add('col-green');
    }
  }, [theme]);

  return (
    <Wrapper show={show}>
      <CHeading className="m-hide" id="c-heading"></CHeading>
      {
        // <canvas id="c" style={{ position: 'fixed' }}>
        // Your browser does not support the HTML5 canvas tag.
        // </canvas>
      }
      <CanvasWrapper id="c-wrapper" />
      <CMore className="m-hide" id="c-more-wrapper">
        <span id="c-more"></span>
        <svg
          width="92"
          height="44"
          viewBox="0 0 92 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1V22H89" stroke="white" strokeWidth="2" />
          <path d="M67 43L90 22L67 0.999999" stroke="white" strokeWidth="2" />
        </svg>
      </CMore>
    </Wrapper>
  );
};

export default Canvas;
