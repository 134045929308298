import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import SEO from '../components/Seo';
import { useCookies } from 'react-cookie';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import ContextConsumer from '../helpers/context';
import W from '../components/styled/Wrapper';
import H from '../components/styled/Heading';
import RichText from '../components/shared/RichText';

import LogoSvg from '../assets/Mediocre-logo-s.svg';

import ArrowSvg from '../assets/arrow-right.svg';

import Footer from '../components/layout/FooterHome';

import Canvas from '../components/home/Canvas';

const Wrapper = styled.div`
  display: block;

  @media only screen and (max-width: 830px) {
    padding-top: 0;
  }

  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 85px;
    z-index: 9;
    background-color: ${({ theme }) => theme.colorBg};
    box-shadow: 0 0 30px 25px ${({ theme }) => theme.colorBg};
    pointer-events: none;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};

    @media only screen and (max-width: 830px) {
      height: 50px;
      box-shadow: 0 0 30px 25px ${({ theme }) => theme.colorBg};
    }
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
  min-height: calc(100vh - ${({ higher }) => (higher ? '130' : '70')}px);
  min-height: calc(
    (var(--vh, 1vh) * 100) - ${({ higher }) => (higher ? '130' : '70')}px
  );

  @media only screen and (max-width: 830px) {
    min-height: auto;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colorBg};
    height: 45vh;
    z-index: 1;
    top: 0;
    box-shadow: 0 0 37px 45px ${({ theme }) => theme.colorBg};

    @media only screen and (max-width: 830px) {
      height: 30vw;
    }
  }
`;

const Logo = styled(LogoSvg)`
  padding-top: 33px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  padding-left: 35px;
  padding-right: 35px;
  position: relative;
  z-index: 3;

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }

  @media only screen and (max-width: 830px) {
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Lead = styled(H)`
  line-height: 78px;
  max-width: 920px;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 80px;
  line-height: 96px;
  max-width: ${({ isEn }) => (isEn ? '1140' : '920')}px;

  @media only screen and (max-width: 830px) {
    margin-bottom: 90px;
    font-size: 42px;
    line-height: 54px;
    max-width: ${({ enStyle }) => (enStyle ? 550 : 500)}px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 84px;
    margin-bottom: ${({ enStyle }) => (enStyle ? 50 : 84)}px;
    font-size: 27px;
    line-height: 34px;
    max-width: 100%;
    max-width: 360px;
  }

  /* strong {
    font-family: ${({ theme }) => theme.fontSerif};
    font-size: 102px;
    line-height: 122px;
    font-weight: normal;

    @media only screen and (max-width: 830px) {
      font-size: 36px;
      line-height: 38px;
    }
  } */
`;

const TopText = styled(W)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: 830px) {
    display: block;
  }
`;

const Bottom = styled(W)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - ${({ higher }) => (higher ? '130' : '70')}px);
  min-height: calc(
    (var(--vh, 1vh) * 100) - ${({ higher }) => (higher ? '130' : '70')}px
  );
`;

const TextWrapper = styled.div`
  padding-top: ${({ higher }) => (higher ? '60' : '157')}px;
  max-width: 800px;
  max-width: ${({ isEn }) => (isEn ? '780' : '800')}px;
  align-self: flex-end;
  padding-bottom: 100vh;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};

  @media only screen and (max-width: 830px) {
    padding-top: 10px;
    padding-right: 0;
  }
`;

const Text = styled(H)`
  margin-bottom: 112px;
  line-height: 1;

  @media only screen and (max-width: 830px) {
    font-size: 24px;
    line-height: 29px;
    max-width: 391px;
    margin-left: auto;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19px;
    max-width: 240px;
    margin-left: auto;
  }

  /* strong {
    font-family: ${({ theme }) => theme.fontSans};
    font-size: 40px;
    line-height: 1;
    font-weight: normal;

    @media only screen and (max-width: 830px) {
      font-size: 17px;
      line-height: 20px;
    }
  } */
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 40px;
  line-height: 1;

  span {
    margin-bottom: 20px;
    font-family: ${({ theme }) => theme.fontSans};
    font-size: 32px;
    line-height: 38px;

    @media only screen and (max-width: 830px) {
      margin-bottom: 15px;
      font-size: 42px;
      line-height: 50px;
    }

    @media only screen and (max-width: 600px) {
      margin-bottom: 15px;
      font-size: 25px;
      line-height: 30px;
    }
  }
`;

const Arrow = styled(ArrowSvg)`
  @media only screen and (max-width: 830px) {
    height: 70px;
    width: 140px;
  }

  @media only screen and (max-width: 600px) {
    height: 25px;
    width: 50px;
    margin-bottom: 10px;
  }

  path,
  rect {
    stroke: ${({ theme }) => theme.colorMain} !important;
  }
`;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* position: ${({ fix }) => (fix ? 'fixed' : 'relative')};
  z-index: 9;
  bottom: 20px;
  width: 100%; */
  padding-bottom: ${({ higher }) => (higher ? 30 : 0)}px;

  @media only screen and (max-width: 830px) {
    padding-bottom: ${({ higher }) => (higher ? 15 : 0)}px;
    display: block;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: ${({ higher }) => (higher ? 25 : 0)}px;
  }
`;

const Home = ({
  lead,
  text,
  seoTitle,
  seoDescription,
  seoImage,
  contextData,
  setContext,
  goToLabel,
  langTo,
  projectsLink,
  homeCanvasItems,
  enStyle,
}) => {
  const [cookies] = useCookies(['m-cn']);
  const [higher, setHigher] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setContext({
          showcase: true,
        });
      }, 250);
    }
  }, [inView]);

  useEffect(() => {
    if (cookies['m-cn']) {
      setHigher(false);
    } else {
      setHigher(true);
    }
  }, [cookies]);

  useEffect(() => {
    setContext({ langTo: langTo });
    document.body.classList.remove('other-page');
    document.body.classList.add('index-page');
    return () => {
      document.body.classList.remove('index-page');
      document.body.classList.add('other-page');
    };
  }, []);

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <React.Fragment>
          <SEO title={seoTitle} description={seoDescription} image={seoImage} />
          <Wrapper hide={data.showcase}>
            <Top higher={higher} hide={data.showcase}>
              <Logo />
              <TopText>
                {lead && (
                  <Lead size={2} as="div" enStyle={enStyle} isEn={data.isEn}>
                    <RichText
                      content={lead && lead.raw}
                      noStyles={true}
                    ></RichText>
                  </Lead>
                )}
              </TopText>
            </Top>

            <Bottom higher={higher}>
              <TextWrapper
                higher={higher}
                hide={data.showcase}
                isEn={data.isEn}
              >
                <Text as="div" size={5}>
                  <RichText content={text.raw} noStyles={true}></RichText>
                </Text>
              </TextWrapper>
              <FooterWrapper fix={data.showcase} ref={ref} higher={higher}>
                <StyledLink to={projectsLink}>
                  <span>{goToLabel}</span>
                  <Arrow />
                </StyledLink>
                <Footer />
              </FooterWrapper>
            </Bottom>
          </Wrapper>

          <Canvas
            items={homeCanvasItems}
            show={data.showcase}
            setContext={set}
            inverted={data.inverted}
            theme={data.theme}
            isEn={data.isEn}
          ></Canvas>
          
        </React.Fragment>
      )}
    </ContextConsumer>
  );
};

export default Home;
