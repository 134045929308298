import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import ContextConsumer from '../../helpers/context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: 830px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Copyrights = styled.div`
  margin-bottom: 8px;

  @media only screen and (max-width: 830px) {
    margin-bottom: 0;
    font-size: 19px;
    line-height: 23px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 11px;
    line-height: 14px;
  }
`;

const Line = styled.div`
  display: flex;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 830px) {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 14px;
  }
`;

const Text = styled.h3`
  font-size: 16px;
  line-height: 1;

  @media only screen and (max-width: 830px) {
    font-size: 19px;
    line-height: 23px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 16px;
  }
`;

const Address = styled(Text)`
  @media only screen and (max-width: 830px) {
    max-width: 260px;
  }
`;

const Footer = ({ isEn }) => {
  const general = useStaticQuery(graphql`
    query footerHome {
      med {
        pl: pageContact(
          where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }
          locales: pl_PL
        ) {
          address
          footerContactHeading
          heading2
          mapsUrl
          seoDescription
          email
        }
        en: pageContact(
          where: { id: "ckjtspcaw2okg0b53vcsq2p8a" }
          locales: en
        ) {
          address
          footerContactHeading
          heading2
          mapsUrl
          seoDescription
          email
        }
      }
    }
  `);

  const [data, setData] = useState(false);

  useEffect(() => {
    setData(isEn ? general.med.en : general.med.pl);
  }, [isEn]);

  return (
    <Wrapper>
      <div>
        <Copyrights>© Mediocre</Copyrights>
        <Line>
          <Text>{data.footerContactHeading}&nbsp;</Text>
          <Text as="a" href={`mailto:${data.email}`}>
            {data.email}
          </Text>
        </Line>
      </div>
      <Line>
        <Text>{data.heading2}&nbsp;</Text>
        <Address as="a" href={data.mapsUrl} target="_blank">
          {data.address}
        </Address>
      </Line>
    </Wrapper>
  );
};

const ConnectedFooter = () => {
  return (
    <ContextConsumer>
      {({ data, set }) => <Footer isEn={data.isEn} />}
    </ContextConsumer>
  );
};

export default ConnectedFooter;
